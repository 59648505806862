import React, { ReactNode } from 'react';

import { Carousel } from 'antd';
import styled from 'styled-components';

type TDotCarouselProps = {
    children: ReactNode;
};

const DotCarousel = ({ children }: TDotCarouselProps): JSX.Element => {
    const onChange = (currentSlide: number) => {
        console.log(currentSlide);
    };

    return (
        <StyledCarousel autoplay waitForAnimate afterChange={onChange}>
            {children}
        </StyledCarousel>
    );
};

export default DotCarousel;

const StyledCarousel = styled(Carousel)`
    .slick-dots li.slick-active button {
        background-color: #3866df;
        width: 10px;
        height: 10px;
        border-radius: 20px;
    }
    .slick-dots li button {
        background-color: #f2f6fb;
        border: 1px solid #3866df;
        width: 10px;
        height: 10px;
        border-radius: 20px;
    }
    .slick-dots li.slick-active {
        width: 10px;
        height: 10px;
        border-radius: 20px;
    }
    .slick-dots li {
        width: 10px;
        height: 10px;
        border-radius: 20px;
    }
    .slick-dots-bottom {
        bottom: -20px;
    }
`;
