import React, { memo } from 'react';

import styled from 'styled-components';
import { useTranslation } from 'next-i18next';

type TProps = {
    title?: string;
};
const FaqPageHeading = ({ title }: TProps) => {
    const { t } = useTranslation('advertise');

    return (
        <Wrapper>
            <Title>{t('faq')}</Title>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    margin-top: 59px;
    height: auto;
`;
const Title = styled.h2`
    color: #343434;
    display: flex;
    text-align: center;
    letter-spacing: -2.8px;
    font-weight: 700;
    font-size: 80px;
    line-height: 0px;

    @media (max-width: 1199px) {
        max-width: 100%;
        margin-top: 40px;
        font-size: 60px;
        // line-height: 50px;
    }
    @media (max-width: 991px) {
        max-width: 100%;
        margin-top: 40px;
        font-size: 40px;
        line-height: 50px;
    }
`;
export default memo(FaqPageHeading);
