import { useCallback, useEffect } from 'react';

import { useRouter } from 'next/router';
import { isMobileOnly, isTablet } from 'react-device-detect';

import { recordToAdtechCom } from '@mp-ads/core/utils/analytics';

type TProps = {
    pageName: string;
};

const usePageTracking = ({ pageName }: TProps): null => {
    const router = useRouter();

    const getDeviceType = () => {
        if (isMobileOnly) {
            return 'mobile';
        }
        if (isTablet) {
            return 'tablet';
        }
        return 'desktop';
    };
    const pageTracking = useCallback(() => {
        const source_tag = router?.query ? router.query : '';
        const deviceType = getDeviceType();
        recordToAdtechCom({ ev: pageName, device: deviceType, source_tag });
    }, [!pageName, router.query]);

    useEffect(() => {
        pageTracking();
    }, [router.query]);

    return null;
};

export default usePageTracking;
