import React from 'react';

import styled from 'styled-components';

type TProps = {
    videoSrc: string;
    bgColor?: string;
    isMuted?: boolean;
    isControl?: boolean;
};

const CustomVideo = ({ bgColor, isControl = false, isMuted = false, videoSrc }: TProps) => (
    <VideoWrapper bgColor={bgColor}>
        {isControl ? (
            <CustomVid width="auto" height="auto" autoPlay muted={isMuted} loop controls={isControl} playsInline>
                <source src={videoSrc} type="video/mp4" />
            </CustomVid>
        ) : (
            <MarqueVideo width="auto" height="auto" autoPlay muted loop playsInline>
                <source src={videoSrc} type="video/mp4" />
            </MarqueVideo>
        )}
    </VideoWrapper>
);

const VideoWrapper = styled.div<{ bgColor?: string }>`
    display: flex;
    flex-direction: column;
    display: flex;
    margin-top: 41px;
    width: 100%;
    justify-content: center;
    border-radius: 20px;
    background-color: ${({ bgColor }) => bgColor || '#e3e5e7'};
    @media (max-width: 991px) {
        margin-top: 40px;
        padding: 0 20px;
    }
`;
const CustomVid = styled.video`
    border-radius: 20px;
    max-height: 684px;
    outline: none;
    border: 0px;
    border-radius: 8px;
`;

const MarqueVideo = styled.video`
    border-radius: 20px;
    max-height: 684px;
`;
export default CustomVideo;
