import React, { memo } from 'react';

import styled from 'styled-components';

import { getDirection } from '../utils';

type TProps = {
    title: string;
};
const PageTopHeaderTitle = ({ title }: TProps) => {
    const isRtl = getDirection();
    return (
        <>
            <Title isrtl={isRtl}>{title}</Title>
        </>
    );
};

const Wrapper = styled.div``;
const Title = styled.h1<{ isrtl: boolean }>`
    color: #343434;
    text-align: center;
    font-size: 80px;
    font-weight: 700;
    line-height: 0px;
    letter-spacing: -1.8px;

    @media (max-width: 1199px) {
        max-width: 100%;
        margin-top: 10px;
        font-size: 60px;
        text-align: center;
        font-style: normal;
        line-height: 43px; /* 107.5% */
    }
    @media (max-width: 991px) {
        max-width: 100%;
        margin-top: 10px;
        font-size: 40px;
        color: #343434;
        text-align: center;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: 43px; /* 107.5% */
        letter-spacing: -1.8px;
    }
    @media (max-width: 791px) {
        max-width: 100%;
        margin-top: 10px;
        font-size: 40px;
        color: #343434;
        text-align: center;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 43px; /* 107.5% */
        letter-spacing: -1.8px;
        width: 314px;
    }
`;
export default memo(PageTopHeaderTitle);
