import { useEffect, useState } from 'react';

import Image from 'next/image';
import Link from 'next/link';
import styled, { CSSProperties } from 'styled-components';

import { recordToAdtechCom } from '@mp-ads/core/utils/analytics';

import { getDirection } from '../../utils';

type TProps = {
    title: string;
    subTitle: string;
    pathText: string;
    pathLink: string;
    imageSrc: string;
    styles?: CSSProperties;
};

const AdsSolution = ({ imageSrc, pathLink, pathText, styles, subTitle, title }: TProps): JSX.Element => {
    const [isMobile, setIsMobile] = useState(false);
    const [isTablet, setIsTablet] = useState(false);

    const isRTL = getDirection();

    const imageSpecificStyle = () => {
        switch (imageSrc) {
            case 'images/crm.png':
                return '90%';
            case 'images/offSite.png':
                return '80%';
            case 'images/holdingImage.png':
                return '80%';
            case 'images/holdingImage2.png':
                return '70%';
            case 'images/brandproduct.png':
                return '80%';
            case 'images/brandproduct_ar.png':
                return '90%';
            case 'images/productPhone.png':
                return !isTablet ? '75%' : '80%';
            case 'images/productPhone_ar.png':
                return !isTablet ? '70%' : '80%';
            case 'images/productAds.png':
                return !isTablet ? '80%' : '78%';
            case 'images/productAds_ar.png':
                return !isTablet ? '90%' : '88%';
            case 'images/managedDisplayAds.png':
            case 'images/managedDisplayAds_ar.png':
                return '90%';
            case 'images/sponsored.png':
                return !isTablet ? '90%' : '90%';
            default:
                return 'auto';
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
            setIsTablet(window.innerWidth <= 1455);
        };
        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleNatAction = (pathName: string) => {
        recordToAdtechCom({ ev: pathName, curl: `/${pathLink}` });
    };

    return (
        <AdTypeWrapper>
            <Div44 isrtl={isRTL}>
                <Title>{title}</Title>
                <AdsTypeSubText>{subTitle}</AdsTypeSubText>
                <LearnAndLink>
                    <Link href={`/${pathLink}`} passHref onClick={() => handleNatAction(pathLink)}>
                        {pathText}
                    </Link>
                    <ArrowIcon isRTL={isRTL} loading="eager" src="/icons/arrow-right-blue.svg" alt="arrow" />
                </LearnAndLink>
            </Div44>
            <ImageWrapper>
                <ImageContainer image={imageSrc} isRTL={isRTL}>
                    <Image
                        width={400}
                        height={400}
                        loading="lazy"
                        src={`/${imageSrc}`}
                        alt="title"
                        style={{
                            width: imageSpecificStyle(),
                            height: imageSpecificStyle(),
                            objectFit: 'contain',
                        }}
                    />
                </ImageContainer>
            </ImageWrapper>
        </AdTypeWrapper>
    );
};

const AdTypeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 100%;
    border-radius: 20px;
    background-color: #f6f7f9;
    color: #404553;

    @media (max-width: 900px) {
        height: auto;
    }

    @media (max-width: 991px) {
        width: 100%;
        margin-top: 20px;
        min-height: 0px;
    }

    @media (min-width: 1000px) {
        height: 813px;
    }

    @media (min-width: 1900px) {
        height: 830px;
    }
`;

const Div44 = styled.div<{ isrtl: boolean }>`
    padding: 50px 40px 0px 50px;
    @media (max-width: 991px) {
        max-width: 100%;
        padding: ${({ isrtl }) => (isrtl ? '0px 40px 0px 20px' : '0px 0px 0px 20px')};
    }
`;

const Title = styled.h2`
    margin: 0px;
    letter-spacing: -0.8px;
    font-weight: 700;
    font-size: 2.5rem;
    @media (max-width: 991px) {
        max-width: 100%;
        color: #404553;
        // font-family: 'Plus Jakarta Sans';
        font-size: 1.7rem;
        font-style: normal;
        font-weight: 700;
        line-height: 60px; /* 300% */
        letter-spacing: -0.4px;
    }
    @media (max-width: 500px) {
        font-size: 1.25rem;
    }
`;

const AdsTypeSubText = styled.div`
    margin-top: 31px;
    // font-family: 'Plus Jakarta Sans';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 150% */
    opacity: 0.8;
    @media (max-width: 991px) {
        max-width: 100%;
        color: #404553;
        // font-family: 'Proxima Nova, sans-serif';
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 140% */
        opacity: 0.8;

        margin-top: 0px;
    }
    @media (max-width: 500px) {
        width: 300px;
        font-size: 15px;
        font-weight: 500;
        line-height: 21px;
    }
`;

const LearnAndLink = styled.div`
    display: flex;
    margin-top: 33px;
    gap: 3px;
    font-size: 1.563rem;
    color: #3866df;
    align-items: center;
    cursor: pointer;

    a {
        color: #3866df;
        font-size: 1.563rem;
        font-style: normal;
        // font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        line-height: 30px; /* 120% */
        @media (max-width: 991px) {
            max-width: 100%;
            font-size: 1.263rem;
            margin-top: 0px;
        }
        @media (max-width: 500px) {
            font-size: 1.063rem;
        }
    }
    @media (max-width: 991px) {
        max-width: 100%;
        font-size: 1.263rem;
        margin-top: 11px;
    }
    @media (max-width: 500px) {
        font-size: 1.063rem;
    }
`;

const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: auto;
`;

const ImageContainer = styled.div<{ image: string; isRTL: boolean }>`
    width: 100%;
    position: relative;
    display: flex;

    right: ${(props) =>
        props.image === 'images/sponsored.png' || props.image === 'images/productAds.png' ? '0' : '5%'};

    justify-content: ${(props) => (props.isRTL ? 'flex-start' : 'flex-end')};
    @media (max-width: 991px) {
        right: ${(props) =>
            props.image === 'images/sponsored.png' || props.image === 'images/productAds.png' ? '0' : '3%'};
    }
`;

const ArrowIcon = styled.img<{ isRTL: boolean }>`
    height: 30px;
    position: relative;
    overflow: hidden;
    padding-top: 5px;
    transform: ${(props) => (props.isRTL ? 'rotate(180deg)' : 'rotate(0deg)')};
    margin-top: ${(props) => (props.isRTL ? '10px' : '0px')};
`;

export default AdsSolution;
