import React, { memo } from 'react';

import styled from 'styled-components';
import { useTranslation } from 'next-i18next';

type TProps = {
    title?: string;
};
const TestamoniHeading = ({ title }: TProps) => {
    const { t } = useTranslation('advertise');

    return (
        <Wrapper>
            <Title>{t('feedback_top_text')}</Title>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    // margin-top: 59px;
`;
const Title = styled.span`
    color: #343434;
    display: flex;
    text-align: center;
    letter-spacing: -2.8px;
    justify-content: center;
    width: 981px;
    font-size: 80px;
    font-weight: 700;
    line-height: 84px;

    @media (max-width: 1199px) {
        max-width: 100%;
        font-size: 60px;
        line-height: 65px;
        text-align: center;
        width: 530px;
    }

    @media (max-width: 991px) {
        max-width: 100%;
        font-size: 40px;
        line-height: 65px;
        width: 530px;
    }

    @media (max-width: 500px) {
        width: 330px;
        color: #343434;
        text-align: center;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 43px;
        letter-spacing: -1.8px;
    }
`;
export default memo(TestamoniHeading);
