import { useState } from 'react';

import { Trans, useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Link from 'next/link';
import styled from 'styled-components';

import { recordToAdtechCom } from '@mp-ads/core/utils/analytics';

import AdsSolution from '../components/AdSolutions/AdsSolution';
import DotCarousel from '../components/Carousel';
import CustomVideo from '../components/CustomVideo';
import FaqPageHeading from '../components/FaqPageHeading';
import PageTopHeaderTitle from '../components/PageTopHeaderTitle';
import TestamoniHeading from '../components/TestamoniHeading';
import usePageTracking from '../hooks/usePageTracking';
import useWindowSize from '../hooks/useWindowSize';
import { getBasicMetaTags, mainMetaData } from '../metaHelper';
import { getDirection } from '../utils';

const MyComponent = (props): JSX.Element => {
    const { t } = useTranslation('advertise');
    const isRTL = getDirection();

    // NAT Traking for Analytics
    usePageTracking({ pageName: 'homepage' });
    const [openContentDetails1, setOpenContentDetails1] = useState(true);
    const [openContentDetails2, setOpenContentDetails2] = useState(false);
    const [openContentDetails3, setOpenContentDetails3] = useState(false);
    const [openContentDetails4, setOpenContentDetails4] = useState(false);
    const [openContentDetails5, setOpenContentDetails5] = useState(false);

    const windowSizes = {
        mobileSize: 768,
        tabletSize: 991,
    };
    const { isMobile, isTablet } = useWindowSize(windowSizes);

    const handleContent1 = () => {
        setOpenContentDetails1(!openContentDetails1);
    };

    const handleContent2 = () => {
        setOpenContentDetails2(!openContentDetails2);
    };

    const handleContent3 = () => {
        setOpenContentDetails3(!openContentDetails3);
    };

    const handleContent4 = () => {
        setOpenContentDetails4(!openContentDetails4);
    };

    const handleContent5 = () => {
        setOpenContentDetails5(!openContentDetails5);
    };

    const testamentSamsung = {
        content: t('testament_samsung'),
        announcer: t('ahmed_sultan'),
        position: t('ahmed_sultan_position'),
    };

    const testamentLoreal = {
        content: t('testament_loreal'),
        announcer: t('dania_elhussein'),
        position: t('dania_elhussein_position'),
    };

    const testamentMotorola = {
        content: t('testament_motorola'),
        announcer: t('vinayak_shenoy'),
        position: t('vinayak_shenoy_position'),
    };

    const activeCardOnboard = {
        header: t('onboard'),
        content: t('onboard_subtext'),
    };

    const activeCardReach = {
        header: t('reach'),
        content: t('reach_subtext'),
    };

    const activeCardTarget = {
        header: t('Target'),
        content: t('Target_subtext'),
    };

    const activeCardReact = {
        header: t('react'),
        content: t('react_subtext'),
    };

    const activeCardOptimize = {
        header: t('optimize'),
        content: t('optimize_subtext'),
    };

    const activeCardGrow = {
        header: t('grow'),
        content: t('grow_subtext'),
    };

    const handleStartNow = () => {
        recordToAdtechCom({ ev: 'start_now' });
    };

    const metaData = getBasicMetaTags(mainMetaData.title, mainMetaData.description, mainMetaData.og_image);

    return (
        <Wrapper>
            {metaData}
            <MainContainer>
                <TopSection>
                    {!isMobile ? (
                        <>
                            <StartNow isrtl={isRTL}>
                                <Link
                                    onClick={handleStartNow}
                                    href="https://admanager.noon.partners/en-ae/?utm_source=ad_site&utm_medium=home"
                                    passHref
                                    target="_blank"
                                >
                                    {t('start_now')}
                                </Link>
                            </StartNow>
                            <TopContainerImage
                                loading="lazy"
                                srcSet={isRTL ? '/images/final-HB-ar.gif' : '/images/final-HB.gif'}
                            />
                        </>
                    ) : (
                        <>
                            <StartNow isrtl={isRTL}>
                                <Link
                                    onClick={handleStartNow}
                                    href="https://admanager.noon.partners/en-ae/?utm_source=ad_site&utm_medium=home"
                                    passHref
                                    target="_blank"
                                >
                                    {t('start_now')}
                                </Link>
                            </StartNow>
                            <TopContainerImageMobile
                                loading="lazy"
                                srcSet={isRTL ? '/images/mobileHeroV2_ar.gif' : '/images/mobileHeroV2.gif'}
                            />
                        </>
                    )}
                </TopSection>
                <PageTopHeaderTitle title={t('start_ads_today')} />
                <>
                    {!isMobile ? (
                        <>
                            <Div12>
                                <Div13>
                                    <Column>
                                        <Div14>
                                            <Img3 loading="lazy" src="images/onboard.svg" />
                                            <TargetHeading>{activeCardOnboard.header}</TargetHeading>
                                            <TargetSubheading>{activeCardOnboard.content}</TargetSubheading>
                                        </Div14>
                                    </Column>
                                    <Column>
                                        <Div14>
                                            <Img4 style={{ marginTop: -4 }} loading="lazy" src="images/reach.svg" />
                                            <TargetHeading style={{ marginTop: 9 }}>
                                                {activeCardReach.header}
                                            </TargetHeading>
                                            <TargetSubheading style={{ marginTop: 23 }}>
                                                {activeCardReach.content}
                                            </TargetSubheading>
                                        </Div14>
                                    </Column>
                                    <Column>
                                        <Div14>
                                            <Img4 style={{ marginTop: -4 }} loading="lazy" src="images/target.svg" />
                                            <TargetHeading>{activeCardTarget.header}</TargetHeading>
                                            <TargetSubheading style={{ marginTop: 21 }}>
                                                {activeCardTarget.content}
                                            </TargetSubheading>
                                        </Div14>
                                    </Column>
                                </Div13>
                            </Div12>
                            <Div12>
                                <Div24>
                                    <Column>
                                        <Div14>
                                            <Img5 loading="lazy" src="images/react.svg" />
                                            <TargetHeading>{activeCardReact.header}</TargetHeading>
                                            <TargetSubheading>{activeCardReact.content}</TargetSubheading>
                                        </Div14>
                                    </Column>
                                    <Column>
                                        <Div14>
                                            <Img6 style={{ marginTop: -4 }} loading="lazy" src="images/optimize.svg" />
                                            <TargetHeading>{activeCardOptimize.header}</TargetHeading>
                                            <TargetSubheading>{activeCardOptimize.content}</TargetSubheading>
                                        </Div14>
                                    </Column>
                                    <Column>
                                        <Div14>
                                            <Img7 style={{ marginTop: -10 }} loading="lazy" src="images/grow.svg" />
                                            <TargetHeading>{activeCardGrow.header}</TargetHeading>
                                            <TargetSubheading>{activeCardGrow.content}</TargetSubheading>
                                        </Div14>
                                    </Column>
                                </Div24>
                            </Div12>
                        </>
                    ) : (
                        <Div74>
                            <DotCarousel>
                                <div>
                                    <CardStyle>
                                        <Div12>
                                            <Div24>
                                                <Column>
                                                    <Div14>
                                                        <Img5 loading="lazy" src="images/onboard.svg" />
                                                        <TargetHeading>{activeCardOnboard.header}</TargetHeading>
                                                        <TargetSubheading>{activeCardOnboard.content}</TargetSubheading>
                                                    </Div14>
                                                </Column>
                                            </Div24>
                                        </Div12>
                                    </CardStyle>
                                </div>
                                <div>
                                    <CardStyle>
                                        <Div12>
                                            <Div24>
                                                <Column>
                                                    <Div14>
                                                        <Img5 loading="lazy" src="images/reach.svg" />
                                                        <TargetHeading>{activeCardReach.header}</TargetHeading>
                                                        <TargetSubheading>{activeCardReach.content}</TargetSubheading>
                                                    </Div14>
                                                </Column>
                                            </Div24>
                                        </Div12>
                                    </CardStyle>
                                </div>
                                <div>
                                    <CardStyle>
                                        <Div12>
                                            <Div24>
                                                <Column>
                                                    <Div14>
                                                        <Img5 loading="lazy" src="images/target.svg" />
                                                        <TargetHeading>{activeCardTarget.header}</TargetHeading>
                                                        <TargetSubheading>{activeCardTarget.content}</TargetSubheading>
                                                    </Div14>
                                                </Column>
                                            </Div24>
                                        </Div12>
                                    </CardStyle>
                                </div>
                                <div>
                                    <CardStyle>
                                        <Div12>
                                            <Div24>
                                                <Column>
                                                    <Div14>
                                                        <Img5 loading="lazy" src="images/react.svg" />
                                                        <TargetHeading>{activeCardReact.header}</TargetHeading>
                                                        <TargetSubheading>{activeCardReact.content}</TargetSubheading>
                                                    </Div14>
                                                </Column>
                                            </Div24>
                                        </Div12>
                                    </CardStyle>
                                </div>
                                <div>
                                    <CardStyle>
                                        <Div12>
                                            <Div24>
                                                <Column>
                                                    <Div14>
                                                        <Img5 loading="lazy" src="images/optimize.svg" />
                                                        <TargetHeading>{activeCardOptimize.header}</TargetHeading>
                                                        <TargetSubheading>
                                                            {activeCardOptimize.content}
                                                        </TargetSubheading>
                                                    </Div14>
                                                </Column>
                                            </Div24>
                                        </Div12>
                                    </CardStyle>
                                </div>
                                <div>
                                    <CardStyle>
                                        <Div12>
                                            <Div24>
                                                <Column>
                                                    <Div14>
                                                        <Img5 loading="lazy" src="images/grow.svg" />
                                                        <TargetHeading>{activeCardGrow.header}</TargetHeading>
                                                        <TargetSubheading>{activeCardGrow.content}</TargetSubheading>
                                                    </Div14>
                                                </Column>
                                            </Div24>
                                        </Div12>
                                    </CardStyle>
                                </div>
                            </DotCarousel>
                        </Div74>
                    )}
                </>

                <Div34>
                    <Div35>
                        <Div36>
                            <Trans
                                i18nKey="noon_ads_carrousel"
                                t={t}
                                components={{
                                    1: <span style={{ color: '#F3E008;' }}>10,000+</span>,
                                    2: <span style={{ color: '#F3E008' }}>noon</span>,
                                }}
                            />
                        </Div36>

                        <BrandWrapper>
                            <div className="marquee">
                                <CustomVideo videoSrc="/videos/brandMarq.mp4" bgColor="#000" />
                            </div>
                        </BrandWrapper>
                    </Div35>
                </Div34>
                <AdTypeDescrioption>{t('noon_ads_desc')}</AdTypeDescrioption>
                <Div50>
                    <Div40>
                        <Column8>
                            <AdsSolution
                                title={t('display_ads')}
                                subTitle={t('display_ads_subtext')}
                                imageSrc={isRTL ? 'images/productPhone_ar.png' : 'images/productPhone.png'}
                                pathText={t('learn_more')}
                                pathLink="display"
                                // styles={{ aspectRatio: '1.54', width: '90%', display: 'flex', justifyContent: 'flex-end' }}
                            />
                        </Column8>
                        <Column8>
                            <AdsSolution
                                title={t('brand_ads')}
                                subTitle={t('brand_ads_subtext')}
                                imageSrc={isRTL ? 'images/brandproduct_ar.png' : 'images/brandproduct.png'}
                                pathText={t('learn_more')}
                                pathLink="brand"
                                // styles={{ aspectRatio: '1.78', width: '90%', display: 'flex', justifyContent: 'flex-end' }}
                            />
                        </Column8>
                    </Div40>
                </Div50>
                <Div50>
                    <Div40>
                        <Column8>
                            <AdsSolution
                                title={t('product_ads')}
                                subTitle={t('product_ads_subtext')}
                                imageSrc={isRTL ? 'images/productAds_ar.png' : 'images/productAds.png'}
                                pathText={t('learn_more')}
                                pathLink="product"
                            />
                        </Column8>
                        <Column8>
                            <AdsSolution
                                title={t('managed_ads')}
                                subTitle={t('managed_ads_subtext')}
                                imageSrc={isRTL ? 'images/managedDisplayAds_ar.png' : 'images/managedDisplayAds.png'}
                                pathText={t('contact_us_lm')}
                                pathLink="/request"
                            />
                        </Column8>
                    </Div40>
                </Div50>
                <Div50>
                    <Div40>
                        <Column8>
                            <AdsSolution
                                title={t('crm_sm')}
                                subTitle={t('crm_sm_subtext')}
                                imageSrc="images/crm.png"
                                pathText={t('contact_us_lm')}
                                pathLink="/request"
                            />
                        </Column8>
                        <Column8>
                            <AdsSolution
                                title={t('offsite_solutions')}
                                subTitle={t('offsite_solutions_subtext')}
                                pathText={t('contact_us_lm')}
                                pathLink="/request"
                                imageSrc={isMobile ? 'images/holdingImage.png' : 'images/holdingImage2.png'}
                            />
                        </Column8>
                    </Div40>
                </Div50>
                <Testamonial>
                    <TestamoniHeading />
                    <FeedbackWrapper>
                        {!isMobile ? (
                            <DotCarousel>
                                <div>
                                    <ContentStyle>
                                        <FeedBackRow>
                                            <FeedbackItem>
                                                <ItemContent>
                                                    <div>
                                                        <ImgWrapper>
                                                            <Img23 loading="lazy" srcSet="images/samsungLogo.png" />
                                                        </ImgWrapper>
                                                        <FeedbackText>{testamentSamsung.content}</FeedbackText>
                                                        <Signature>
                                                            <Announcer>{testamentSamsung.announcer}</Announcer>
                                                            <Position>{testamentSamsung.position}</Position>
                                                        </Signature>
                                                    </div>
                                                </ItemContent>
                                            </FeedbackItem>
                                            <FeedbackItem>
                                                <ItemContent>
                                                    <div>
                                                        <ImgWrapper>
                                                            <Img24 loading="lazy" srcSet="images/lorealLogo.png" />
                                                        </ImgWrapper>
                                                        <FeedbackText>{testamentLoreal.content}</FeedbackText>
                                                        <Signature>
                                                            <Announcer style={{ marginTop: isTablet ? 75 : 40 }}>
                                                                {testamentLoreal.announcer}
                                                            </Announcer>
                                                            <Position>{testamentLoreal.position}</Position>
                                                        </Signature>
                                                    </div>
                                                </ItemContent>
                                            </FeedbackItem>
                                            <FeedbackItem>
                                                <ItemContent>
                                                    <div>
                                                        <ImgWrapper>
                                                            <Img25 loading="lazy" srcSet="icons/motorola.png" />
                                                        </ImgWrapper>
                                                        <FeedbackText style={{ marginTop: !isTablet ? 15 : '' }}>
                                                            {testamentMotorola.content}
                                                        </FeedbackText>
                                                        <Signature>
                                                            <Announcer>{testamentMotorola.announcer}</Announcer>
                                                            <Position>{testamentMotorola.position}</Position>
                                                        </Signature>
                                                    </div>
                                                </ItemContent>
                                            </FeedbackItem>
                                        </FeedBackRow>
                                    </ContentStyle>
                                </div>
                            </DotCarousel>
                        ) : (
                            <DotCarousel>
                                <div>
                                    <ContentStyle>
                                        <FeedBackRow>
                                            <FeedbackItem>
                                                <ItemContent>
                                                    <div>
                                                        <ImgWrapper>
                                                            <Img23 loading="lazy" srcSet="images/samsungLogo.png" />
                                                        </ImgWrapper>
                                                        <FeedbackText>{testamentSamsung.content}</FeedbackText>
                                                        <Signature>
                                                            <Announcer>{testamentSamsung.announcer}</Announcer>
                                                            <Position>{testamentSamsung.position}</Position>
                                                        </Signature>
                                                    </div>
                                                </ItemContent>
                                            </FeedbackItem>
                                        </FeedBackRow>
                                    </ContentStyle>
                                </div>
                                <div>
                                    <ContentStyle>
                                        <FeedBackRow>
                                            <FeedbackItem>
                                                <ItemContent>
                                                    <div>
                                                        <ImgWrapper>
                                                            <Img23 loading="lazy" srcSet="images/lorealLogo.png" />
                                                        </ImgWrapper>
                                                        <FeedbackText>{testamentLoreal.content}</FeedbackText>
                                                        <Signature>
                                                            <Announcer style={{ marginTop: isTablet ? 70 : 40 }}>
                                                                {testamentLoreal.announcer}
                                                            </Announcer>
                                                            <Position>{testamentLoreal.position}</Position>
                                                        </Signature>
                                                    </div>
                                                </ItemContent>
                                            </FeedbackItem>
                                        </FeedBackRow>
                                    </ContentStyle>
                                </div>
                                <div>
                                    <ContentStyle>
                                        <FeedBackRow>
                                            <FeedbackItem>
                                                <ItemContent>
                                                    <div>
                                                        <ImgWrapper>
                                                            <ImgMotorola loading="lazy" src="icons/motorola.png" />
                                                        </ImgWrapper>
                                                        <FeedbackText>{testamentMotorola.content}</FeedbackText>
                                                        <Signature>
                                                            <Announcer>{testamentMotorola.announcer}</Announcer>
                                                            <Position>{testamentMotorola.position}</Position>
                                                        </Signature>
                                                    </div>
                                                </ItemContent>
                                            </FeedbackItem>
                                        </FeedBackRow>
                                    </ContentStyle>
                                </div>
                            </DotCarousel>
                        )}
                    </FeedbackWrapper>
                </Testamonial>

                <FaqPageHeading title={t('faq')} />
                <Div89 onClick={() => handleContent1()}>
                    <Div90>
                        <FaqHeading>{t('noon_ads_q1')}</FaqHeading>
                        {openContentDetails1 ? (
                            <Img28 loading="lazy" src="images/minusSign.svg" />
                        ) : (
                            <Img28 loading="lazy" src="images/pulseSign.svg" />
                        )}
                    </Div90>
                    {openContentDetails1 && <FaqSubtitle>{t('noon_ads_a1')}</FaqSubtitle>}
                </Div89>
                <FaqContainer onClick={() => handleContent2()}>
                    <Div90>
                        <FaqHeading>{t('noon_ads_q2')}</FaqHeading>
                        {openContentDetails2 ? (
                            <Img28 loading="lazy" src="images/minusSign.svg" />
                        ) : (
                            <Img28 loading="lazy" src="images/pulseSign.svg" />
                        )}
                    </Div90>
                    {openContentDetails2 && <FaqSubtitle>{t('noon_ads_a2')}</FaqSubtitle>}
                </FaqContainer>
                <FaqContainer onClick={() => handleContent3()}>
                    <Div90>
                        <FaqHeading>{t('noon_ads_q3')}</FaqHeading>
                        {openContentDetails3 ? (
                            <Img28 loading="lazy" src="images/minusSign.svg" />
                        ) : (
                            <Img28 loading="lazy" src="images/pulseSign.svg" />
                        )}
                    </Div90>
                    {openContentDetails3 && <FaqSubtitle>{t('noon_ads_a3')}</FaqSubtitle>}
                </FaqContainer>
                <FaqContainer onClick={() => handleContent4()}>
                    <Div90>
                        <FaqHeading>{t('noon_ads_q4')}</FaqHeading>
                        {openContentDetails4 ? (
                            <Img28 loading="lazy" src="images/minusSign.svg" />
                        ) : (
                            <Img28 loading="lazy" src="images/pulseSign.svg" />
                        )}
                    </Div90>
                    {openContentDetails4 && <FaqSubtitle>{t('noon_ads_a4')}</FaqSubtitle>}
                </FaqContainer>
                <FaqContainer onClick={() => handleContent5()}>
                    <Div90>
                        <FaqHeading>{t('noon_ads_q5')}</FaqHeading>
                        {openContentDetails5 ? (
                            <Img28 loading="lazy" src="images/minusSign.svg" />
                        ) : (
                            <Img28 loading="lazy" src="images/pulseSign.svg" />
                        )}
                    </Div90>
                    {openContentDetails5 && (
                        <FaqSubtitle>
                            {t('noon_ads_a5')}{' '}
                            <LinkText href="mailto:adsupport@noon.com" target="_blank">
                                adsupport@noon.com
                            </LinkText>
                        </FaqSubtitle>
                    )}
                </FaqContainer>
            </MainContainer>
        </Wrapper>
    );
};

const StartNow = styled.div<{ isrtl: boolean }>`
    border-radius: 321px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
    background-color: #060606;
    color: #f3e008;
    padding: 0.4% 2%;
    cursor: pointer;
    position: absolute;
    margin-left: ${({ isrtl }) => (isrtl ? '45%' : '-18%')};
    margin-top: 35%;
    transform: translate(-50%, -50%);
    z-index: 999;
    font-size: 25px;
    :hover {
        background: linear-gradient(to right, rgb(50, 104, 248), rgb(100, 74, 255));
    }
    a {
        color: #f3e008;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
    }
    @media (min-width: 2050px) {
        margin-top: 650px !important;
    }
    @media (min-width: 2001px) {
        margin-top: 32%;
    }
    @media (max-width: 2000px) {
        margin-top: 33%;
    }
    @media (max-width: 1850px) {
        margin-top: 35%;
    }
    @media (max-width: 1851px) {
        margin-top: 35%;
    }
    @media (max-width: 1810px) {
        margin-top: 36%;
    }
    @media (max-width: 1600px) {
        margin-left: ${({ isrtl }) => (isrtl ? '50%' : '-22%')};
        margin-top: 40%;
    }
    @media (max-width: 1498px) {
        margin-top: 43%;
        margin-left: ${({ isrtl }) => (isrtl ? '55%' : '-25%')};
    }
    @media (max-width: 1350px) {
        margin-left: ${({ isrtl }) => (isrtl ? '50%' : '-23%')};
        margin-top: 43%;
    }
    @media (max-width: 1200px) {
        margin-left: ${({ isrtl }) => (isrtl ? '52%' : '-22%')};
        margin-top: 43%;
    }
    @media (max-width: 1020px) {
        margin-left: ${({ isrtl }) => (isrtl ? '52%' : '-20%')};
        margin-top: 43%;
    }
    @media (max-width: 991px) {
        margin-left: ${({ isrtl }) => (isrtl ? '45%' : '-18%')};
        margin-top: 42%;
    }
    @media (max-width: 768px) {
        margin-left: ${({ isrtl }) => (isrtl ? '14%' : '20%')};
        margin-top: 98%;
        font-size: 15px;
    }
    @media (max-width: 733px) {
        margin-top: 100%;
    }
    @media (max-width: 704px) {
        margin-top: 104%;
    }
    @media (max-width: 540px) {
        margin-top: 137%;
    }
    @media (max-width: 500px), (max-width: 414px), (max-width: 400px), (max-width: 375px) {
        margin-left: ${({ isrtl }) => (isrtl ? '25%' : '29%')};
        margin-top: 168%;
        padding: 3% 6%;
        font-size: 15px;
    }
    @media (max-width: 280px) {
        margin-top: 165%;
        padding: 3% 6%;
    }
`;

const Wrapper = styled.div``;

const CardStyle = styled.div`
    margin: 0;
    height: 250px;
    line-height: 100px;
    @media (max-width: 500px) {
        :nth-child(1) {
            margin: 0px 5px;
        }
    }
`;

const LinkText = styled.a`
    color: #3168f8;
    text-decoration: underline;
    :hover {
        text-decoration: underline;
    }
`;

const MainContainer = styled.div`
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 0px;
    @media (max-width: 991px) {
        padding: 0px 0px;
    }
`;

const TopSection = styled.div`
    display: flex;
    justify-content: center;
    width: -webkit-fill-available;
    height: 100%;
    max-height: 800px;
`;

const TopContainerImage = styled.img`
    aspect-ratio: 1.79;
    object-fit: auto;
    object-position: center;
    width: 100%;
    margin-top: 16px;
    max-width: auto;
    @media (max-width: 991px) {
        max-width: 100%;
        margin-top: 0px;
    }
`;

const TopContainerImageMobile = styled.img`
    object-fit: auto;
    object-position: center;
    width: 100%;
    margin-top: 16px;
    max-width: auto;
    @media (max-width: 991px) {
        max-width: 100%;
        margin-top: 0px;
    }
`;
const Div11 = styled.div`
    color: #343434;
    text-align: center;
    font-size: 80px;
    font-weight: 700;
    // font-family: 'Plus Jakarta Sans';
    line-height: 84px; /* 105% */
    letter-spacing: -1.8px;
    @media (max-width: 991px) {
        max-width: 100%;
        margin-top: 10px;
        font-size: 40px;
        color: #343434;
        text-align: center;
        // font-family: 'Plus Jakarta Sans';
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 43px; /* 107.5% */
        letter-spacing: -1.8px;
        width: 314px;
    }
`;
const Div12 = styled.div`
    margin-top: 48px;
    width: 100%;
    padding: 0 0px;
    @media (max-width: 991px) {
        max-width: 100%;
        margin-top: 0px;
    }
`;
const Div13 = styled.div`
    gap: 20px;
    display: flex;
    @media (max-width: 991px) {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
    }
`;
const Column = styled.div`
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 33%;
    margin-left: 0px;
    @media (max-width: 991px) {
        width: 100%;
        min-height: 250px;
    }
`;
const Div14 = styled.div`
    border-radius: 20px;
    background-color: rgba(242, 246, 251, 1);
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: start;
    color: #404553;
    width: 100%;
    padding: 26px 40px 26px 27px;
    @media (max-width: 991px) {
        max-width: 100%;
        margin-top: 20px;
        padding: 10px 20px;
    }
`;
const Img3 = styled.img`
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: auto;
`;
const TargetHeading = styled.h2`
    margin-top: 16px;
    color: #404553;

    font-size: 1.625rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
    margin-bottom: 0px;

    @media (max-width: 500px) {
        font-size: 1.25rem;
        line-height: 21px;
        margin-top: 10px;
    }
`;
const TargetSubheading = styled.h3`
    margin-top: 26px;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 150% */
    opacity: 0.8;
    @media (max-width: 500px) {
        font-size: 0.998rem;
        line-height: 21px;
        margin-top: 12px;
        font-weight: 500;
    }
`;

const Img4 = styled.img`
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: auto;
`;

const Div24 = styled.div`
    gap: 20px;
    display: flex;
    @media (max-width: 991px) {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
    }
`;

const Img5 = styled.img`
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: auto;
`;

const Img6 = styled.img`
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: auto;
`;

const Img7 = styled.img`
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: auto;
`;

const Div34 = styled.div`
    border-radius: 30px;
    background-color: #000;
    display: flex;
    margin-top: 40px;
    width: 100%;
    /* max-width: 1351px; */
    align-items: center;
    justify-content: center;
    padding: 20px 60px 20px 60px;
    @media (max-width: 991px) {
        max-width: 100%;
        margin-top: 40px;
        padding: 10px 20px;
    }
`;
const Div35 = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    @media (max-width: 991px) {
        max-width: 100%;
    }
`;
const Div36 = styled.div`
    color: white;
    text-align: center;
    font-size: 80px;
    font-weight: 700;
    @media (max-width: 991px) {
        max-width: 100%;
        font-size: 50px;
        letter-spacing: -1.5px;
        line-height: 55px;
    }

    @media (max-width: 500px) {
        max-width: 100%;
        font-size: 15px;
        letter-spacing: 1px;
        line-height: 31px;
    }
    span {
        color: #f3e008;
    }
`;

const BrandWrapper = styled.div`
    margin-left: -4px;
    margin-right: -4px;
    margin-top: -30px;
    @media (max-width: 500px) {
        margin-top: -35px;
    }
    @keyframes marqueeAnimation {
        0% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(-100%);
        }
    }

    .marquee {
        overflow: hidden;
        position: relative;
        margin: 0px 10px;

        @media (max-width: 991px) {
            margin: 0px 20px;
        }
        @media (max-width: 500px) {
            margin: 0px 0px;
        }
    }

    .marquee-content {
        display: flex;
        white-space: nowrap;
        gap: 8%;
        animation: marqueeAnimation 18s linear infinite;
        padding: 25px 0px 0px 0px;
        @media (max-width: 991px) {
            padding: 10px 0px 10px 0px;
        }
    }
`;
const AdTypeDescrioption = styled.h3`
    color: #343434;
    display: display;
    text-align: center;
    letter-spacing: -2.8px;
    margin-top: 40px;
    margin-bottom: 40px;
    width: 987px;
    font-size: 80px;
    font-weight: 700;
    line-height: 100px;
    @media (max-width: 991px) {
        max-width: 100%;
        margin-top: 40px;
        margin-bottom: 0px;
        font-size: 40px;
        line-height: 47px;
    }
`;
const Div40 = styled.div`
    gap: 30px;
    display: flex;
    @media (max-width: 991px) {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
    }
`;
const Column8 = styled.div`
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 50%;

    @media (max-width: 991px) {
        width: 100%;
        margin-left: 0;
    }
`;
const Div50 = styled.div`
    margin-top: 28px;
    width: 100%;
    @media (max-width: 991px) {
        max-width: 100%;
        margin-top: 0px;
    }
`;
const Testamonial = styled.div`
    background-color: #f2f6fb;
    align-self: stretch;
    display: flex;
    margin-top: 74px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    padding: 20px 0 69px 0px;
    @media (max-width: 991px) {
        max-width: 100%;
        padding-left: 20px;
        margin-top: 40px;
        padding: 20px 0px 69px 0px;
        margin-right: -1px;
    }
`;
const FeedBackTopText = styled.div`
    color: #343434;
    text-align: center;
    letter-spacing: -2.8px;
    width: 987px;
    font-weight: 600;
    font-size: 80px;
    @media (max-width: 991px) {
        max-width: 100%;
        font-size: 40px;
        line-height: 47px;
    }
    @media (max-width: 500px) {
        width: 330px;
        color: #343434;
        text-align: center;
        // font-family: 'Plus Jakarta Sans';
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 43px; /* 107.5% */
        letter-spacing: -1.8px;
    }
`;
const Div74 = styled.div`
    align-self: stretch;
    margin-top: 66px;
    border-radius: 48px;
    @media (max-width: 991px) {
        max-width: 100%;
        margin-top: 10px;
    }
`;
const Img23 = styled.img`
    aspect-ratio: 4.76;
    object-fit: auto;
    object-position: center;
    width: 147px;
    max-width: 100%;
`;
const Img24 = styled.img`
    aspect-ratio: 4.57;
    object-fit: auto;
    object-position: center;
    width: 130px;
    max-width: 100%;
`;
const Img25 = styled.img`
    /* aspect-ratio: 1.33; */
    /* object-fit: auto; */
    /* object-position: center; */
    width: auto;
`;
const Faq = styled.div`
    color: #343434;
    text-align: center;
    letter-spacing: -2.8px;
    margin-top: 59px;
    font: 700 80px/105%;
    @media (max-width: 991px) {
        max-width: 100%;
        margin-top: 40px;
        font-size: 40px;
    }
`;
const Div89 = styled.div`
    border-radius: 20px;
    background-color: #f6f7f9;
    display: flex;
    margin-top: 83px;
    width: 100%;
    max-width: 1142px;
    flex-direction: column;
    color: #404553;
    letter-spacing: -1px;
    padding: 16px 32px;
    @media (max-width: 991px) {
        max-width: 100%;
        margin-top: 40px;
        padding: 20px 20px;
    }
`;
const FaqContainer = styled.div`
    border-radius: 20px;
    background-color: #f6f7f9;
    display: flex;
    margin-top: 20px;
    width: 100%;
    max-width: 1142px;
    flex-direction: column;
    color: #404553;
    letter-spacing: -1px;
    padding: 16px 32px;
    @media (max-width: 991px) {
        max-width: 100%;
        margin-top: 20px;
        padding: 10px 20px;
    }
`;
const Div90 = styled.div`
    display: flex;
    gap: 20px;
    font-size: 24px;
    font-weight: 600;
    line-height: 117%;
    @media (max-width: 991px) {
        /* flex-wrap: wrap; */
    }
`;
const FaqHeading = styled.div`
    flex-grow: 1;
    flex-basis: auto;
    color: #404553;
    font-size: 24px;
    // font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 116.667% */
    letter-spacing: -1px;
    @media (max-width: 991px) {
        max-width: 100%;
        font-size: 1.225rem;
    }
    @media (max-width: 500px) {
        color: #404553;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 155.556% */
        letter-spacing: -1px;
    }
`;

const FaqSubtitle = styled.div`
    margin-top: 12px;
    font: 400 18px/28px;
    color: #404553;
    // font-family: 'Plus Jakarta Sans';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 155.556% */
    letter-spacing: 0px;
    opacity: 0.8;
    @media (max-width: 991px) {
        max-width: 100%;
        font-size: 1rem;
    }

    @media (max-width: 500px) {
        font-size: 0.932rem;
        font-weight: 400;
        line-height: 21px; /* 140% */
    }
`;
const Img28 = styled.img`
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 18px;
    margin: auto 0;
`;
const ContentStyle = styled.div`
    margin: 0;
    height: auto;
    line-height: 100px;
    gap: 40px;
`;
const FeedbackWrapper = styled.div`
    border-radius: 20px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    font-size: 22px;
    color: #404553;
    font-weight: 500;
    width: 100%;
    padding: 20px;
    @media (max-width: 991px) {
        max-width: 100%;
        margin-top: 21px;
        padding: 0px;
    }
`;

const FeedBackRow = styled.div`
    display: flex;
    padding: 20px;
    gap: 20px;
    @media (max-width: 1230px) {
        max-width: 100%;
        gap: 15px;
    }
    @media (max-width: 1285px) {
        max-width: 100%;
        padding: 0px;
    }
    @media (max-width: 991px) {
        flex-direction: column;
        align-items: stretch;
        gap: 10px;
        :nth-child(1) {
            margin: 0px 5px;
        }
    }
`;

const FeedbackItem = styled.div`
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 37%;
    flex-grow: 1;
    flex-shrink: inherit;
    min-height: 600px;
    @media (max-width: 991px) {
        width: 100%;
        margin-left: 0;
    }
    @media (max-width: 1199px) {
        min-height: 620px;
    }
    @media (max-width: 991px) {
        min-height: 376px;
    }

    @media (max-width: 500px) {
        min-height: 500px;
    }
`;
const ImgWrapper = styled.div`
    min-height: 36px;
    display: flex;
    align-content: center;
`;
const FeedbackText = styled.div`
    // font-family: 'Plus Jakarta Sans';
    color: #404553;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 136.364% */
    opacity: 0.8;
    margin-top: 41px;
    @media (max-width: 1399px) {
        font-size: 1.3rem;
    }
    @media (max-width: 1200px) {
        font-size: 1.22rem;
        line-height: 28px; /* 136.364% */
    }
    @media (max-width: 1100px) {
        font-size: 1.1rem;
        line-height: 28px; /* 136.364% */
    }
    @media (max-width: 991px) {
        margin-top: 40px;
    }
    @media (max-width: 500px) {
        margin-top: 20px;
        font-size: 0.938rem;
    }
`;

const Signature = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 40px;
    @media (min-width: 1300px) {
        bottom: 60px;
    }
    @media (max-width: 991px) {
        bottom: auto;
    }
    @media (max-width: 500px) {
        bottom: 20px;
    }
`;

const ItemContent = styled.div`
    border-radius: 20px;
    box-shadow: 4px 2px 24px 0px rgba(103, 156, 236, 0.17);
    justify-content: space-between;
    background-color: #fff;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    font-size: 1.375rem;
    color: #404553;
    font-weight: 500;
    width: 100%;
    padding: 28px;
    @media (max-width: 1285px) {
        max-width: 100%;
        padding: 20px;
    }

    @media (max-width: 991px) {
        max-width: 100%;
        margin-top: 21px;
        padding: 20px;
    }
`;
const Announcer = styled.div`
    letter-spacing: -0.8px;
    font-size: 1.8rem;
    font-weight: 700;
    @media (max-width: 1399px) {
        font-size: 1.775rem;
    }
    @media (max-width: 1200px) {
        font-size: 1.62rem;
    }
    @media (max-width: 991px) {
        margin-top: 40px;
    }
    @media (max-width: 500px) {
        font-size: 1.25rem;
    }
`;

const Position = styled.div`
    opacity: 0.8;
    color: #404553;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 30px;
    @media (max-width: 1399px) {
        font-size: 1.1rem;
    }
    @media (max-width: 1210px) {
        font-size: 1rem;
    }
    @media (max-width: 1100px) {
        font-size: 0.899rem;
    }
    @media (max-width: 991px) {
        /* margin-top: 40px; */
    }
    @media (max-width: 500px) {
        font-size: 0.938rem;
        opacity: 0.8;
        font-weight: 400;
    }
`;

const ImgMotorola = styled.img`
    aspect-ratio: 4.76;
    object-fit: auto;
    object-position: center;
    width: 147px;
    max-width: 100%;
    @media (max-width: 991px) {
        /* width: 110px; */
        /* height: 45px; */
    }
`;

export const getServerSideProps = async ({ locale }): Promise<any> => ({
    props: {
        ...(await serverSideTranslations(locale, ['advertise'])),
        meta_data: mainMetaData,
    },
});

export default MyComponent;
